import Head from "next/head";
import useTranslation from "next-translate/useTranslation";

interface MetadataProps {
    title?: string;
    image?: string;
    description?: string;
}

const sitename = "Lead Healthcare";
const baseUrl = process.env.NEXT_PUBLIC_BASE_URL as string;
const defaultImage = `${baseUrl}/images/seo-banner-1200x630.webp`;

export function Metadata({
    title,
    description,
    image = defaultImage
}: MetadataProps): JSX.Element {
    const { t } = useTranslation();
    const pageDescription: string = description ?? t("common:pagedescription");
    const pageTitle: string = title
        ? `${title} | ${sitename}`
        : `${sitename} | ${t("common:pagetitle")}`;

    return (
        <>
            <Head>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta property="og:title" content={pageTitle} />
                <meta property="og:image" content={image} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:description" content={pageDescription} />
            </Head>
        </>
    );
}
